import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PageConst from '../../constants/patientpage';
import './style.css';
import HairImage from '../../assets/images/hair_icon.png';
import ColorButton from '../../Components/Patient/ColorButton';
import IconColorButton from '../../Components/Patient/IconColorButton';
import Str from '../../constants/string';
import Color from '../../constants/usercolor';
import Storages from '../../constants/storages';
import { HiddenMenu } from '../../Components/Location/HiddenMenu'
import apiService from '../../services/api';
import { NumPadModal } from '../../Components/Modal/NumPad';
import LockScreen from './LockScreen';
import QRCode from '../../Components/Location/QRCode';

export default function Welcome (props) {
  const { isCalling } = props;
  const [logo, setLogo] = useState('');
  const [company, setCompany] = React.useState("");
  const [locationName, setLocaitonNmae] = React.useState("");
  const [showLangPrompt, _setShowLangPrompt] = useState(window.i18n.multiLang)
  const showLangPromptRef = useRef(showLangPrompt)
  const setShowLangPrompt = (newVal) => {
    _setShowLangPrompt(newVal)
    showLangPromptRef.current = newVal;
  }
  const [showLockScreen, _setShowLockScreen] = useState(false)
  const showLockScreenRef = useRef(showLockScreen)
  const setShowLockScreen = (newVal) => {
    _setShowLockScreen(newVal)
    showLockScreenRef.current = newVal;
  }
  const isCallingRef = useRef()
  isCallingRef.current = isCalling;
  // on this page we need a state for this to be able to change it
  const [callReceptionButtonText, setCallReceptionButtonText] = useState("")
  const idleTimeout = useRef(null);

  useEffect(() => {
    setCompany(localStorage.getItem(Storages.LOCAL_COMPANY_NAME))
    setLocaitonNmae(localStorage.getItem(Storages.LOCAL_LOCATION_NAME))

    if (localStorage.getItem("lockScreenType") && localStorage.getItem("lockScreenType") != "0") {
      setShowLockScreen(true)
    } else {
      setShowLockScreen(false)
    }

    let logoName = localStorage.getItem("logo");
    if (!logoName || logoName === "null") {
      setLogo("")
    } else if (logoName.startsWith("http://") || logoName.startsWith("https://")) {
      setLogo(logoName)
    } else {
      setLogo(process.env.REACT_APP_UPLOAD_URL + logoName)
    }

    if(window.electron) {
      idleTimeout.current = setInterval(async () => {
        if(isCallingRef.current) return;

        const idleTime = await window.electron.getSystemIdleTime();
        if(idleTime > 30) {
          if(!showLangPromptRef.current && window.i18n.multiLang) {
            window.i18n.currentLangCode = window.i18n.defaultLang;
            setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
            setShowLangPrompt(true)
            sendLangToReceptions();
          }
        }
        if(idleTime > 60) {
          const shouldShowLockScreen = localStorage.getItem("lockScreenType") && localStorage.getItem("lockScreenType") != "0"
          if(!showLockScreenRef.current && shouldShowLockScreen) {
            setShowLockScreen(true)
          }
        }
      }, 1000);
    }

    return () => {
      clearInterval(idleTimeout.current)
    }
  }, []);

  useEffect(() => {
    if (!isCalling) {
      window.i18n.currentLangCode = window.i18n.defaultLang; // when come back to this page, reset lang to default
      setShowLangPrompt(window.i18n.multiLang) // when come back to this page, go to lang prompt
      setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
      sendLangToReceptions();
    }
  }, [isCalling])

  const handleYes = () => {
    if (isCalling) {
      return;
    }
    props.moveToPage(PageConst.APPOINTMENT);
  }

  const handleNo = () => {
    if (isCalling) {
      return;
    }
    props.handleShowCallDialog(Str.STR_NO_APPOINTMENT, false);
  }

  const handleCall = () => {
    if (isCalling) {
      return;
    }
    props.handleShowCallDialog(Str.STR_NORMAL, false);
  }

  const changeLang = (lang) => {
    window.i18n.currentLangCode = lang.code
    setCallReceptionButtonText(window.i18n.getString("callReceptionForHelp"))
    setShowLangPrompt(false)
    sendLangToReceptions();
  }

  const sendLangToReceptions = async () => {
    try {
      const sendLangResult = await apiService.changeLocationLang({
        newLang: window.i18n.currentLangCode
      })
      if (sendLangResult.data?.code !== 0) {
        console.error(8791, sendLangResult)
      }
    } catch (err) {
      console.error(9901, err)
    }
  }

  return (
    <div className='root'>
      <HiddenMenu />
      <QRCode />
      <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
      <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
      <div className='d-flex flex-column align-items-center justify-content-start w-100 h-100'>
        <div className='d-flex align-items-end justify-content-center p-3'
          style={{ height: "30vh", width: "45vw" }}>
          {logo && <img style={{ maxHeight: "100%", maxWidth: "100%", zIndex: 1 }} src={logo} />}
        </div>
        {showLangPrompt ?
          <>
            <label className='welcom_lab mt-5'>
              {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
            </label>
            <label className='main_lab mb-4'>
              {window.i18n.getString("chooseLanguage")}
            </label>
            <div className='bottom_div mb-5'>
              {window.i18n.langs.map(lang => (
                <ColorButton onClicked={() => changeLang(lang)} Title={lang.title} Color={Color.PURPLE_BTN_COLOR} />
              ))}
            </div>
          </>
        : showLockScreen ?
          <>
            <label className='welcom_lab'>
              {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
            </label>
            <LockScreen logo={logo} handleClose={() => setShowLockScreen(false)} />
          </>
        :
          <>
            <label className='welcom_lab'>
              {window.i18n.getString("welcomeTo", { locationName: (!locationName || locationName === 'null' ? company : locationName) })}
            </label>
            <label className='sub_lab' id='welcom_sub_lab'>
              {window.i18n.getString("welcomeMakeSelection")}
            </label>
            <label className='main_lab'>
              {window.i18n.getString("haveAppointmentQuestion")}
            </label>
            <div className='bottom_div mb-5'>
              <ColorButton onClicked={handleYes} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.GREEN_BTN_COLOR}
                Title={window.i18n.getString("yes")} />
              <ColorButton onClicked={handleNo} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.YELLOW_BTN_COLOR}
                Title={window.i18n.getString("no")} />
            </div>
          </>
        }
      </div>
      {!showLockScreen ?
        <div id="call_div">
          <IconColorButton onClicked={handleCall} Color={isCalling ? Color.GRAY_BTN_COLOR : Color.PURPLE_BTN_COLOR}
            Icon={HairImage} Title={callReceptionButtonText} />
        </div>
      : ""}
    </div>
  )
}

Welcome.prototype = {

}
