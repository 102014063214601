import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PageConst from '../../constants/patientpage';
import './style.css';
import HairImage from '../../assets/images/hair_icon.png';
import ColorButton from '../../Components/Patient/ColorButton';
import IconColorButton from '../../Components/Patient/IconColorButton';
import Str from '../../constants/string';
import Color from '../../constants/usercolor';
import Storages from '../../constants/storages';
import {HiddenMenu} from  '../../Components/Location/HiddenMenu'
import apiService from '../../services/api';
import { NumPadModal } from '../../Components/Modal/NumPad';
import Backspace from "@mui/icons-material/Backspace";
import Check from "@mui/icons-material/Check";
import { toast } from 'react-toastify';
import HoldButton from '../../Components/Location/HoldButton';

export default function LockScreen ({logo, handleClose}) {
    const [type, setType] = useState(localStorage.getItem("lockScreenType"));
    const [code, setCode] = useState('')

    useEffect(() => {
      setCode('')
    }, []);

    const onChange=(n)=>{
      let newCode = "" + code + n
      setCode(newCode)
      if(newCode && newCode.length == 3) {
        handleCheckCode(newCode)
      }
    }

    const onKeyUp = (e) => {
      if(e.key === "Backspace"){
        clean()
      } else if (!isNaN(e.key)) {
        onChange(e.key)
      }
    }

    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        e.stopPropagation()
        handleCheckCode(code)
      } 
    }

    const clean=()=>{
      setCode(code.slice(0, -1))
    }

    const handleCheckCode = (newCode) => {
      if(newCode == "186") {
        handleClose()
      } else {
        toast.warning("Please enter 186.")
        setCode('')
      }
    }

    return (
      <div style={{zIndex: 2}}>
        {type === "1" ?
          <>
            <label className='main_lab mb-2' style={{fontSize: "2.5vw"}}>
              {window.i18n.getString("lockScreenPinText", {pin: "186"})}
            </label>
            <div className="pad">
              <div className="numpad-row">
                  <input className="input-numpad input-numpad-big" placeholder='Enter Code' value={code} onKeyUp={onKeyUp} onKeyDown={onKeyDown} />
              </div>    
              <div className="numpad-row">
                <button className="key big-key" onClick={() => onChange(1)}>
                  1
                </button>
                <button className="key big-key" onClick={() => onChange(2)}>
                  2
                </button>
                <button className="key big-key" onClick={() => onChange(3)}>
                  3
                </button>
              </div>
              <div className="numpad-row">
                <button className="key big-key" onClick={() => onChange(4)}>
                  4
                </button>
                <button className="key big-key" onClick={() => onChange(5)}>
                  5
                </button>
                <button className="key big-key" onClick={() => onChange(6)}>
                  6
                </button>
              </div>
              <div className="numpad-row">
                <button className="key big-key" onClick={() => onChange(7)}>
                  7
                </button>
                <button className="key big-key" onClick={() => onChange(8)}>
                  8
                </button>
                <button className="key big-key" onClick={() => onChange(9)}>
                  9
                </button>
              </div>
              <div className="numpad-row">
                <button className="key-action big-action-key" onClick={()=>handleCheckCode(code)}>
                  <Check fontSize='large'/>
                </button>
                <button className="key big-key" onClick={() => onChange(0)}>
                  0
                </button>
                <button className="key-action big-action-key" onClick={()=>clean()} >
                  <Backspace fontSize='large'/>
                </button>
              </div>
          </div>
          </>
        : type === "2" ?
          <div className='d-flex flex-column align-items-center justify-content-center' style={{minHeight: "25vh"}}>
            <label className='main_lab mb-4' style={{fontSize: "3vw"}}>{window.i18n.getString("lockScreenButtonTopText")}</label>
            <HoldButton onHoldComplete={handleClose} />
          </div>
        :
          <></>
        }
      </div>
    )
}
