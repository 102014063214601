import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import apiService from '../services/api';
import Str from '../constants/string';
import Storages from '../constants/storages';
import ReactLoading from 'react-loading';
import { HiddenMenu } from '../Components/Location/HiddenMenu';
import VersionComponent from '../Version'
import Connection from '../Components/User/Connection';
import { useSelector,useDispatch } from 'react-redux';
import tokenService from '../services/tokenService';
import syncSharedFiles from '../services/syncSharedFiles';
import { setScreens } from '../redux/screen';
import { setCustomURLs } from '../redux/customURLs';
import { isSleep } from '../redux/sleepLocation';
import { authenticate } from '../context/socket';
import { loadJitsiLib } from '../services/loadJitsiLib';

export default function OnLoad (props) {
  const { history, onLoadDone } = props;
  const connections = useSelector((state) => state.connections)
  const errorTimeoutRef = useRef();
  const dispatch=useDispatch()
  const navigate = useNavigate();

  useEffect(() => { // also calls the first time
    if(connections.internet && connections.server) {
      checkRoleAndRedirect();
    }
  }, [connections])

  async function checkRoleAndRedirect() { 
    clearTimeout(errorTimeoutRef.current)
    await checkRole();
  }

  async function checkRole() {
    try{
      const role = localStorage.getItem(Storages.LOCAL_ROLE);
      if(window.electron) {
        if(!role) {
          // return history.replace("/first-page")
          return onLoadDone("#/first-page")
        }
        const token = tokenService.get();
        if(!token) {
          if(role === 'reception') {
            // return history.replace("/login")
            return onLoadDone("#/login")
          } else {
            // return history.replace("/first-page")
            return onLoadDone("#/first-page")
          }
        }
      } else {
        const pathname = window.location.pathname;
        if(pathname === "/otp-login") {
          const otpLoginRes = await otpLogin()
          if (otpLoginRes.code !== 0) {
            toast.error(`Invalid otp login link. Code: ${otpLoginRes.code}. ${otpLoginRes.msg}`, {autoClose: 10 * 1000})
            // Change url to clear /otp-login so we won't come back here.
            window.history.pushState(null, '', '/')
            return onLoadDone("#/login")
          } else {
            // Token has been set in otpLogin function. So redirect to slash to restart the application
            return navigate("/", { replace: true });
          }
        } else if(pathname === "/reset-password") {
          const searchParams = new URLSearchParams(window.location.search);
          const tokenParam = searchParams.get('token');
          if(!tokenParam) {
            toast.error("Invalid reset password link. Please try again.")
            onLoadDone("#/login")
            return
          }
          localStorage.setItem("resetPassToken", tokenParam)
          window.history.pushState(null, '', '/')
          return onLoadDone("#/reset-password")
        }
        
        if(!role && process.env.NODE_ENV === "development") {
          return onLoadDone("#/first-page")
        }
        // In production, web apps are only for receptions
        if(role !== "reception" && process.env.NODE_ENV !== "development") {
          tokenService.remove();
          localStorage.setItem(Storages.LOCAL_ROLE, "reception")
          // return history.replace("/login")
          return onLoadDone("#/login")
        }
        const token = tokenService.get();
        if(!token) {
          // return history.replace("/login")
          return onLoadDone("#/login")
        }
      }

      const onLoadRes = await apiService.onLoad();
      const onLoadData = onLoadRes.data;  
      if(!onLoadData || onLoadData.code != 0 || !onLoadData.data?.me) {
        toast.error(
          `Something went wrong when fetching data from server. Retrying...`, 
          {autoClose: 9 * 1000}
        )
        errorTimeoutRef.current = setTimeout(() => {
          checkRoleAndRedirect();
        }, 10 * 1000);
        return;
      }
      if(
        (onLoadData.data.me.type === 0 && role !== "location") || 
        (onLoadData.data.me.type === 1 && role !== "reception")
      ){
        toast.error(`Local data is out of sync with server. Please login again`, {autoClose: false})
        // return history.replace("/")
        return onLoadDone("#/first-page")
      }

      if(role === 'reception') {
        let customURLs=onLoadData.data.customURLs || []
        dispatch(setCustomURLs(customURLs))
        return receptionRole(onLoadData.data.me)
      } else {
        let screens=onLoadData.data.screens
        let sleep=onLoadData.data.me.isSleep
        if(typeof sleep != 'undefined'){
          dispatch(isSleep(sleep))
        }
        dispatch(setScreens(screens))
        return locationRole(onLoadData.data.me);
      }
    } catch (err) {
      console.error(err)
      if(err.message == "Authorization failed. User will be logged out") {
        toast.error("Your session has ended. You will be logged out. Please log in again to continue.")
        checkRoleAndRedirect();
      } else {
        toast.error(err.message + ". Retrying...", {autoClose: 10 * 900})
        errorTimeoutRef.current = setTimeout(() => {
          checkRoleAndRedirect();
        }, 10 * 1000);
      }
    }
  }

  async function locationRole(me) {
    let title = me.title || Str.STR_APP_TITLE;
    if (window.electron) {
      window.electron.setUserName(me.username);
      window.electron.setTitle(title);
    }

    // const roomName = me.roomname.toLowerCase() + `_${me.roomID}`;
    localStorage.setItem(Storages.LOCAL_ROOM_NAME, me.finalJitsiRoomName);
    localStorage.setItem(Storages.LOCAL_LOCATION_NAME, me.locationname);
    localStorage.setItem(Storages.LOCAL_COMPANY_NAME, me.company);
    localStorage.setItem(Storages.LOCAL_KIOSK_USER, me.kiosk || 0);
    localStorage.setItem("integrated_kiosk", me.integratedKiosk || 0);
    localStorage.setItem("kiosk_url", me.kioskUrl);
    localStorage.setItem("kiosk_timeout", me.kioskTimeout);
    localStorage.setItem("admin_panel_code", me.adminPanelCode);
    if(!localStorage.getItem("location_volume") || isNaN(localStorage.getItem("location_volume"))) {
      localStorage.setItem("location_volume", 75);
    }
    // localStorage.setItem(Storages.LOCAL_LOCATION_ID, me.userID)
    localStorage.setItem(Storages.LOCAL_LOGINED_USER, me.username);
    localStorage.setItem("logo", me.logol)
    localStorage.setItem("groupnum", me.roomID);
    if(me.jitsiDnsName) {
      localStorage.setItem("jitsiDnsName", me.jitsiDnsName)
    } else {
      localStorage.removeItem("jitsiDnsName")
    }
    if(me.jitsiMainDomain) {
      localStorage.setItem("jitsiMainDomain", me.jitsiMainDomain)
    } else {
      localStorage.removeItem("jitsiMainDomain")
    }
    if(me.jitsiJwt) {
      localStorage.setItem("jitsiJwt", me.jitsiJwt)
    } else {
      localStorage.removeItem("jitsiJwt")
    }
    if(me.isJaas) {
      localStorage.setItem("isJaas", me.isJaas)
      localStorage.setItem("jaasAppID", me.jaasAppID)
    } else {
      localStorage.removeItem("isJaas")
      localStorage.removeItem("jaasAppID")
    }
    if(me.jitsiMeetId) {
      localStorage.setItem("jitsiMeetId", me.jitsiMeetId)
    }
    if(me.locationIsAway) {
      localStorage.setItem("locationIsAway", true)
    } else {
      localStorage.removeItem("locationIsAway")
    }
    if(me.locationTopBanner && me.locationBottomBanner) {
      if (me.locationTopBanner.startsWith("http://") || me.locationTopBanner.startsWith("https://")) {
        window.locationTopBanner = me.locationTopBanner
      } else {
        window.locationTopBanner = process.env.REACT_APP_UPLOAD_URL + me.locationTopBanner
      }
      if (me.locationBottomBanner.startsWith("http://") || me.locationBottomBanner.startsWith("https://")) {
        window.locationBottomBanner = me.locationBottomBanner
      } else {
        window.locationBottomBanner = process.env.REACT_APP_UPLOAD_URL + me.locationBottomBanner
      }
    }// no else. we set it in App to the default banners, leave it as

    if(me.defaultLocalVideoCons) {
      localStorage.setItem("defaultLocalVideoCons", JSON.stringify(me.defaultLocalVideoCons))
    } else {
      localStorage.removeItem("defaultLocalVideoCons")
    }

    if(me.cardDetectionEnabled) {
      localStorage.setItem("cardDetectionEnabled", "1")
    } else {
      localStorage.removeItem("cardDetectionEnabled")
    }    
    
    if(me.lockScreenType) {
      localStorage.setItem("lockScreenType", me.lockScreenType)
    } else {
      localStorage.removeItem("lockScreenType")
    }

    if(me.qrCode) {
      if (me.qrCode.startsWith("http://") || me.qrCode.startsWith("https://")) {
        localStorage.setItem("qrCodeSrc", me.qrCode)
      } else {
        localStorage.setItem("qrCodeSrc", process.env.REACT_APP_UPLOAD_URL + me.qrCode)
      }
      localStorage.setItem("qrCodeText", me.qrText)
    } else {
      localStorage.removeItem("qrCodeSrc")
      localStorage.removeItem("qrCodeText")
    }
    if(me.jitsiLibUrl) {
      localStorage.setItem("jitsiLibUrl", me.jitsiLibUrl)
    } else {
      localStorage.removeItem("jitsiLibUrl")
    }

    await loadJitsiLib();

    if(me.locationIsAway) {
      // return history.replace('/location/away')
      return onLoadDone('#/location/away')
    } else {
      // return history.replace('/mainlocationpage/' + me.username + '/' + me.clientID)
      return onLoadDone('#/mainlocationpage/' + me.username + '/' + me.clientID)
    }
  }

  async function receptionRole(me) {
    let title = me.title || Str.STR_APP_TITLE;
    if (window.electron) {
      window.electron.setUserName(me.username);
      window.electron.setTitle(title);
    }

    // me.roomname = me.roomname.toLowerCase() + `_${me.roomID}`;
    localStorage.setItem(Storages.LOCAL_ROOM_NAME, me.finalJitsiRoomName);
    localStorage.setItem(Storages.LOCAL_LOGINED_USER, me.username);
    localStorage.setItem(Storages.LOCAL_LOCATION_NAME, me.locationname);
    localStorage.setItem(Storages.LOCAL_RECEPTION_NAME, me.displayName || me.username);
    localStorage.setItem(Storages.LOCAL_COMPANY_NAME, me.company);
    localStorage.setItem("groupnum", me.roomID);
    if(me.showToggleSecondary) {
      localStorage.setItem("showToggleSecondary", me.showToggleSecondary)
    } else {
      localStorage.removeItem("showToggleSecondary")
    }
    if(me.jitsiDnsName) {
      localStorage.setItem("jitsiDnsName", me.jitsiDnsName)
    } else {
      localStorage.removeItem("jitsiDnsName")
    }
    if(me.jitsiMainDomain) {
      localStorage.setItem("jitsiMainDomain", me.jitsiMainDomain)
    } else {
      localStorage.removeItem("jitsiMainDomain")
    }
    if(me.jitsiJwt) {
      localStorage.setItem("jitsiJwt", me.jitsiJwt)
    } else {
      localStorage.removeItem("jitsiJwt")
    }
    if(me.isJaas) {
      localStorage.setItem("isJaas", me.isJaas)
      localStorage.setItem("jaasAppID", me.jaasAppID)
    } else {
      localStorage.removeItem("isJaas")
      localStorage.removeItem("jaasAppID")
    }
    if(me.jitsiMeetId) {
      localStorage.setItem("jitsiMeetId", me.jitsiMeetId)
    }
    if(me.isManager) {
      localStorage.setItem(Storages.IS_RECEPTION_MANAGER, 1);
    } else {
      localStorage.removeItem(Storages.IS_RECEPTION_MANAGER);
    }
    if(me.jitsiLibUrl) {
      localStorage.setItem("jitsiLibUrl", me.jitsiLibUrl)
    } else {
      localStorage.removeItem("jitsiLibUrl")
    }
    await loadJitsiLib();

    if(window.electron) {
      syncSharedFiles().then((data) => {
        if(data.code !== 0) {
          console.error(data.msg)
          toast.error(data.msg, {autoClose: 10000})
        }
      })
    }

    localStorage.setItem("multipleTabPing", "ping" + new Date().valueOf())
    setTimeout(() => {
      if(localStorage.getItem("multipleTabPing") == "pong") {
        return onLoadDone('#/already-connected')
      } else {
        window.addEventListener("storage", (event) => {
          if(event.key == "multipleTabPing") {
            if(event.newValue.startsWith("ping")) {
              localStorage.setItem("multipleTabPing", "pong")
            }
          }
        })

        // return history.replace('/receptionist')
        return onLoadDone('#/receptionist')
      }
    }, 300);
  }

  async function otpLogin() {
    try{
      const searchParams = new URLSearchParams(window.location.search);
      const email = searchParams.get('email');
      const otp = searchParams.get('otp');
      if(!email || !otp) {
        return {code: -111, msg: "Email and otp are required."}
      }

      const loginRes = await apiService.otpLogin({email, otp})
      if(!loginRes.data || loginRes.data.code !== 200) {
        return {code: loginRes.data.code, msg: loginRes.data.msg}
      } else {
        localStorage.setItem(Storages.LOCAL_ROLE, "reception")
        tokenService.set(loginRes.data.data.token)
        authenticate();
        return {code: 0}
      }

    } catch (err) {
      throw err;
    }
  }

  return (
    !connections.internet ?
      <Connection msg="It seems like there is no internet connection. Retrying…"/>
    : !connections.server ?
      <Connection msg="We are currently upgrading and updating our service. 
        We apologize for any inconvenience this may cause. 
        Please be patient while we apply these necessary changes, 
        your system will be back up shortly."/>
    :
    <div className='root'>
      <HiddenMenu />
      <img className='top_tap' src={window.locationTopBanner} alt="top_tap" />
      <img className='bottom_tap' src={window.locationBottomBanner} alt="bottom_tap" />
      <ReactLoading className="m-auto" type={"spin"} color={"#0085d2"} />
      <VersionComponent/>
    </div>
  )
}
